import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/useStore";
import PlayerSection from "./PlayerSection";
import DataPropertiesSection from "./DataPropertiesSection";
import { ScriptSection } from "./ScriptSection";
import { useState } from "react";
import CreateProjectModal from "./CreateProjectModal";
import Header from "./Header";
import { Button, Modal } from "antd";
import { PLATFORM_BASE_URL } from "../consts/consts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 20px;
  width: 100%;
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1.6fr 1fr;
  gap: 20px;
  height: 80vh;
  justify-items: end;
`;

const showCustomSuccessModalAndRedirectTo = (projectId?: string) => {
  Modal.success({
    title: null,
    centered: true,
    width: 505,
    content: (
      <div
        style={{
          fontFamily: "'Inter', sans-serif",
        }}
      >
        <h3
          style={{
            fontWeight: 700,
            margin: "0px",
            fontSize: "16px",
            lineHeight: "26px",
            color: "#130F26",
            marginBottom: "8px",
          }}
        >
          Your Project Is Ready
        </h3>
        <p
          style={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "22px",
            color: "#130F26",
            marginBottom: "24px",
          }}
        >
          Your project has been successfully created and is now ready on your
          platform.
        </p>
      </div>
    ),
    icon: null,
    footer: (
      <div style={{ textAlign: "right" }}>
        <Button
          onClick={() => Modal.destroyAll()}
          style={{
            border: "none",
            boxShadow: "none",
            color: "#6E7079",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.8")}
          onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() =>
            window.open(
              `${PLATFORM_BASE_URL}project/${projectId}/overview`,
              "_blank"
            )
          }
          style={{
            background:
              "linear-gradient(258.38deg, #FF154D 1.82%, #FF154D 35.15%, #FF7AAA 98.2%)",
            border: "none",
            color: "#FFFFFF",
            marginLeft: "8px",
            transition: "opacity 0.3s",
          }}
        >
          Go to Platform
        </Button>
      </div>
    ),
  });
};

const ScriptConverter = observer(() => {
  const { projectStore } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async (projectName: string, account: string) => {
    try {
      projectStore.setName(projectName);
      projectStore.setAccount(account);

      const result = await projectStore.createProject();

      setIsModalOpen(false);

      if (result?.success) {
        showCustomSuccessModalAndRedirectTo(result.projectId);
        handleCancel();
      } else {
        Modal.error({
          title: "Error",
          content: result?.message || "Failed to create project.",
        });
      }
    } catch (err) {
      console.error(err);
      setIsModalOpen(false);
      Modal.error({
        title: "Error",
        content: "An unexpected error occurred while creating the project.",
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Header showModal={showModal} />
      <MainContent>
        <ScriptSection />
        <PlayerSection />
        <DataPropertiesSection />
      </MainContent>

      {isModalOpen && (
        <CreateProjectModal
          isOpen={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      )}
    </Container>
  );
});

export default ScriptConverter;
