import styled from "styled-components";
import { useStore } from "./hooks/useStore";
import ScriptConverter from "./components/ScriptConverter";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { withDefaultSignIn } from "@blings/shared-auth-component";
import { getCurrentUser, signIn, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { getEnv } from "./utils/getEnv";
import { rootStore } from "./models/RootStore";
import { useEffect } from "react";
import { info_data } from "./consts/consts";

const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f5f7fa;
  width: 100%;
`;

function App() {
  const { accountStore } = useStore();

  useEffect(() => {
    const initializeAccount = async () => {
      if (!accountStore.accounts) {
        await accountStore.getAccounts();
      }
    };
    rootStore.platformStore.loadProjects();
    initializeAccount();
  }, []);
  return (
    <AppContainer>
      <ScriptConverter />
    </AppContainer>
  );
}

export default withDefaultSignIn(App, {
  config: {
    amplify: {
      getCurrentUser: getCurrentUser,
      Hub: Hub,
      signIn: signIn,
      signOut: signOut,
    },
    ui: {
      Authenticator: Authenticator,
      ThemeProvider: ThemeProvider,
    },
    environment: getEnv(),
  },
  infoData: {
    contact_email: info_data.contactMail,
    support: info_data.support,
  },
});
