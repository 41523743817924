// src/index.tsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import { createGlobalStyle } from 'styled-components';
import App from './App';
import { RootStore } from './models/RootStore';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { signInWithRedirect } from 'aws-amplify/auth';
import { ConfigureApp } from '@blings/shared-auth-component';

ConfigureApp(awsExports, Amplify, signInWithRedirect);


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
`;


const rootStore = RootStore.create({});

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider value ={rootStore}>
      <App />
    </Provider>
  </React.StrictMode>
);