/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "BlingsAPI",
            "endpoint": "https://n5i659t7zj.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "addPubSubPermission",
            "endpoint": "https://bftopj1a4c.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "extension",
            "endpoint": "https://ykd8o3yvea.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "platformRest",
            "endpoint": "https://1hz5rylbw6.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "projectsRestaApi",
            "endpoint": "https://lja2d0vqji.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "uploadCsvFile",
            "endpoint": "https://2x7o1a5sj4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://7ipvqfvqivgn5kjqyojjbokwaa.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-zmdie233qff6phw5qv2l3xrhgy",
    "aws_cognito_identity_pool_id": "eu-west-1:98875664-5431-4c24-abfa-791bc3d12c62",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_0kZbFWMIr",
    "aws_user_pools_web_client_id": "1pcnq1pb8s975c1nokahlirb4j",
    "oauth": {
        "domain": "platform480c79fd7-80c79fd7-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://platform.blings.io/auth/sign-in/,https://studio.blings.io/auth/sign-in/",
        "redirectSignOut": "https://platform.blings.io/auth/sign-out/,https://studio.blings.io/auth/sign-out/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "platform4-hostingbucket-dev-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://platform4-hostingbucket-dev-dev.s3-website-eu-west-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Config-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "SourceDataRecords-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "Versions-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "platform-storage-bucket-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
