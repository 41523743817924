import { getEnv } from "../utils/getEnv";

export const TEXT_TO_VIDEO_SOURCE = "TEXT_TO_VIDEO_SOURCE" as const;
export const info_data = {
  contactMail: "contact@blings.io",
  support: "support@blings.io",
};

export const PLATFORM_BASE_URL =
  getEnv() === "dev"
    ? "https://dev.platform.blings.io/"
    : "https://platform.blings.io/";
