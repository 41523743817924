import { types, flow, Instance, getRoot } from "mobx-state-tree";
import { generateClient } from "aws-amplify/api";
import {
  createProject,
  updateProject,
  updateProjectVersion,
} from "../graphql/mutations";
import { GraphQLQuery } from "@aws-amplify/api";
import { IRootStore } from "./RootStore";
import { IScriptStore } from "./ScriptStore";
import { convertSchema } from "../utils/convertToSchema";
import { projectTitleToAlias } from "../utils/projectTitleToAlias";
import { Animation } from "@blings/blings-player";
import {
  CreateProjectInput,
  Project,
  ProjectVersion,
  UpdateProjectInput,
  UpdateProjectVersionInput,
  VidPartInput,
} from "../API";
import { uploadAnimations } from "../utils/uploadAnimations";
import { ProjectBasicInfo } from "./PlatformStore";
import { TEXT_TO_VIDEO_SOURCE } from "../consts/consts";
import { getLatestPlayerVersion } from "../utils/getLatestPlayerVersion";

export interface ListProjectsQuery {
  listProjects?: {
    items: Array<{
      id: string;
      name: string;
      description?: string;
    }> | null;
  } | null;
}

const client = generateClient();

export const ProjectStore = types
  .model("ProjectStore", {
    // projects: types.array(Project),
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    account: types.optional(types.string, ""),
    id: types.optional(types.string, ""),
    basicInfo: types.maybeNull(types.frozen<ProjectBasicInfo>()),
    workspaceVersion: types.maybeNull(types.frozen<ProjectVersion>()),
  })
  .views((self) => ({
    get root(): IRootStore {
      return getRoot<IRootStore>(self);
    },
  }))
  .actions((self) => ({
    combineVideoParts(newVideoParts: Array<VidPartInput>) {
      const mapFromDb =
        self.workspaceVersion?.videoParts?.reduce((acc, vp) => {
          const { __typename, ...rest } = vp;
          acc[rest.name] = rest;
          return acc;
        }, {} as Record<string, VidPartInput>) || {};
      const mapFromScript = newVideoParts.reduce((acc, vp) => {
        acc[vp.name] = vp;
        return acc;
      }, {} as Record<string, VidPartInput>);

      Object.keys(mapFromScript).forEach((name) => {
        const vp = mapFromScript[name];
        if (mapFromDb[name]) {
          if (mapFromDb[name].origin === TEXT_TO_VIDEO_SOURCE) {
            const connectorsFromStudio = mapFromDb[name].modsArr?.filter(
              (mod) => mod.origin !== TEXT_TO_VIDEO_SOURCE
            );
            vp.modsArr = vp.modsArr?.concat(connectorsFromStudio || []);
            mapFromDb[name] = vp;
          } else {
            vp.name = vp.name + ` from Blings AI`;
            mapFromDb[vp.name] = vp;
          }
        } else {
          mapFromDb[name] = vp;
        }
      });
      return Object.values(mapFromDb);
    },
    setProject(workspaceVersion: ProjectVersion, title: string) {
      self.workspaceVersion = workspaceVersion;
      self.id = workspaceVersion.id;
      self.name = title;
      self.account = workspaceVersion.accountOwner;
    },
    setName(newName: string) {
      self.name = newName;
    },
    setAccount(newAccount: string) {
      self.account = newAccount;
    },
  }))
  .actions((self) => ({
    createProject: flow(function* () {
      const scriptStore = self.root.scriptStore as IScriptStore;
      if (!self.account) return alert("Please select an account.");
      if (!self.name) return alert("Please enter a project name.");

      const latestPlayerVersion = yield getLatestPlayerVersion();
      const createProjectInput: CreateProjectInput = {
        title: self.name,
        projectAccountId: self.account,
        description: scriptStore.inputText,
        stateJsonSchemaStr: JSON.stringify(
          convertSchema(scriptStore.dynamicData.toJSON())
        ),
        aliasId: projectTitleToAlias(self.name),
        textToVideoScript: scriptStore.formattedScript,
        playerVersionToUse: latestPlayerVersion,
        allowDataConnect: true,
        allowCrmConnect: true,
        allowSdkConnect: true,
      };
      try {
        const project = (yield client.graphql<GraphQLQuery<CreateProjectInput>>(
          {
            query: createProject,
            variables: { input: createProjectInput },
          }
        )).data.createProject as Project;
        const { id: projectId } = project;

        const urls = yield uploadAnimations(projectId, scriptStore.mp5Data);
        let connectorId = 0;
        const videoParts: VidPartInput[] = scriptStore.mp5Data.map(
          (scene: Animation, index: number) => ({
            name: scene.nm || "",
            jsonUrl: urls[index],
            modsArr: scriptStore.connectors[index].map((connector) => ({
              id: connectorId++,
              dataStr: JSON.stringify(connector),
              origin: TEXT_TO_VIDEO_SOURCE,
            })),
            origin: TEXT_TO_VIDEO_SOURCE,
            playerVersionToUse: latestPlayerVersion,
          })
        );

        yield client.graphql<GraphQLQuery<UpdateProjectInput>>({
          query: updateProject,
          variables: {
            input: {
              id: projectId,
              videoParts: videoParts,
            },
          },
        });
        return {
          success: true,
          message: "Project created successfully!",
          projectId,
        };
      } catch (error) {
        console.error("Error creating project:", error);
        return {
          success: false,
          message: "Failed to create project.",
          error,
        };
      }
    }),
    updateProject: flow(function* () {
      const scriptStore = self.root.scriptStore as IScriptStore;
      if (self.id === "") return alert("Please select a project.");
      const projectId = self.id;

      const urls = yield uploadAnimations(projectId, scriptStore.mp5Data);
      const latestPlayerVersion = yield getLatestPlayerVersion();
      let connectorId = 0;
      const videoParts = scriptStore.mp5Data.map<VidPartInput>(
        (scene: Animation, index: number) => ({
          name: scene.nm || "",
          jsonUrl: urls[index],
          modsArr: scriptStore.connectors[index].map((connector) => ({
            id: connectorId++,
            dataStr: JSON.stringify(connector),
            origin: TEXT_TO_VIDEO_SOURCE,
          })),
          origin: TEXT_TO_VIDEO_SOURCE,
          playerVersionToUse: latestPlayerVersion,
        })
      );
      const combinedVideoParts = self.combineVideoParts(videoParts);
      const updateProjectVersionInput: UpdateProjectVersionInput = {
        id: self.id,
        stateJsonSchemaStr: JSON.stringify(
          convertSchema(scriptStore.dynamicData.toJSON())
        ),
        textToVideoScript: scriptStore.formattedScript,
        videoParts: combinedVideoParts,
        playerVersionToUse: latestPlayerVersion,
      };
      try {
        const project = (yield client.graphql<
          GraphQLQuery<UpdateProjectVersionInput>
        >({
          query: updateProjectVersion,
          variables: { input: updateProjectVersionInput },
        })).data.updateProjectVersion as ProjectVersion;

        alert("Project updated successfully!");
      } catch (error) {
        console.error("Error updating project:", error);
        alert("Failed to update project.");
      }
    }),
  }));

export type IProjectStore = Instance<typeof ProjectStore>;
